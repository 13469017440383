<template>
  <v-container class="payment-type payment-type-redirect">
    <div
      class="payment-type-title"
      v-html="paymentType.title"
      v-if="paymentType.title"
    ></div>
    <div class="payment-type-description" v-html="paymentType.descr"></div>
    <PrebilledAmount
      :mode="mode"
      :order-id="orderId"
      :paymentTypeId="paymentType.paymentTypeId"
    />
    <ResponseMessage :response="response" />
    <v-row class="d-flex justify-end">
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center"
        v-if="mode != 'order' && !options.hidePrevButton"
      >
        <LinkButton
          v-if="
            !$vuetify.breakpoint.xs &&
              options.prevButton &&
              options.prevButton.type === 'link-button'
          "
          :class="options.prevButton.class"
          :options="options.prevButton.options"
          :label="options.prevButton.label"
          @click="goBack"
        ></LinkButton>
        <v-btn
          v-else
          outlined
          color="primary"
          depressed
          v-on:click="goBack"
          :disabled="loading"
          x-large
          class="bg-white"
          >{{ $t("common.back") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <v-btn
          v-if="options.confirm.show"
          color="primary"
          class="mt-3 mt-sm-0 ml-2 gateway-btn"
          v-on:click="buy"
          depressed
          x-large
        >
          Conferma ordine
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PrebilledAmount from "./PrebilledAmount";
import ResponseMessage from "@/components/ResponseMessage";
import { payment, handlePayResponseKo } from "./gatewayUtilsService";
import OrderService from "~/service/orderService";
import gateway from "~/mixins/gateway";
import { mapActions } from "vuex";
import LinkButton from "~/components/LinkButton.vue";

export default {
  name: "PaymentTypeRedirect",
  mixins: [gateway],
  data() {
    return { loading: false, error: null, response: {} };
  },
  components: { PrebilledAmount, ResponseMessage, LinkButton },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart",
      updateCurrentOrderId: "app/updateCurrentOrderId"
    }),
    async buy() {
      // UPDATE CART INFO AND PAY
      let vm = this;
      var _this = this;
      vm.loading = true;
      try {
        if (vm.mode == "cart") {
          let cart = await vm.buyCart({
            paymentTypeId: _this.paymentType.paymentTypeId
          });
          if (cart) {
            //aggiungo il CURRENT_ORDER_ID al localStorage
            vm.updateCurrentOrderId(cart.order.orderId);
            vm.payResponseOk(cart);
          } else {
            this.$router.push("/payment-error");
          }
        } else if (vm.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            vm.orderId,
            this.paymentType.paymentTypeId
          );
          if (response) {
            //aggiungo il CURRENT_ORDER_ID al localStorage
            vm.updateCurrentOrderId(vm.orderId);
            vm.payResponseOk(response);
          } else {
            this.$router.push("/payment-error");
          }
        }
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    payResponseOk(cart) {
      let orderId = null;
      if (cart && cart.paymentUrl) {
        global.EventBus.$emit("pay-response-ok", cart);
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        this.doRedirect(
          cart.paymentUrl,
          cart.paymentParameters,
          "get",
          orderId
        );
      } else {
        this.loading = false;
      }
    },
    payResponseKo(data) {
      this.loading = false;

      handlePayResponseKo(data, this.orderId);
    },
    doRedirect(paymentUrl, paymentParameters, method, orderId) {
      payment(paymentUrl, paymentParameters, method, orderId);
    },
    goBack() {
      this.$router.push("/checkout");
    }
  }
};
</script>
